'use strict';

angular.module('risevision.common.header')
  .controller('AuthButtonsCtr', ['$scope',
    'userState', 'companyState', 'userAuthFactory', '$loading', 'bindToScopeWithWatch',
    function ($scope, userState, companyState, userAuthFactory,
      $loading, bindToScopeWithWatch) {

      $scope.spinnerOptions = {
        color: '#999',
        hwaccel: true,
        radius: 10
      };

      //watch on username change and populate onto scope variables requried
      // for rendering UI

      $scope.$watch(function () {
          return userState.getUserPicture();
        },
        function () {
          $scope.userPicture = userState.getUserPicture();
        });
      $scope.$watch(function () {
          return companyState.getUserCompanyName();
        },
        function () {
          $scope.companyName = companyState.getUserCompanyName();
        });

      $scope.$watch(function () {
          return userState.getUsername();
        },
        function () {
          $scope.username = userState.getUsername();
        });
      bindToScopeWithWatch(userState.isAssignedPublisher.bind(userState), 'isAssignedPublisher', $scope);

      $loading.startGlobal('auth-buttons-silent');
      userAuthFactory.authenticate(false).finally(function () {
        $loading.stopGlobal('auth-buttons-silent');
      });

    }
  ]);
