'use strict';

angular.module('risevision.common.header')
  .directive('commonHeader', ['userState', 'companyState', 'bindToScopeWithWatch',
    '$document', 'cookieTester', 'companyIcpFactory', 'environment',
    function (userState, companyState, bindToScopeWithWatch,
      $document, cookieTester, companyIcpFactory, environment) {
      return {
        restrict: 'E',
        templateUrl: 'partials/common-header/common-header.html',
        scope: false,
        link: function ($scope) {
          companyIcpFactory.init();
          cookieTester.checkCookies().then(function () {
            $scope.cookieEnabled = true;
          }, function () {
            $scope.cookieEnabled = false;
          });
          $scope.navCollapsed = true;
          $scope.isSubcompanySelected = companyState.isSubcompanySelected.bind(companyState);
          $scope.ENV_NAME = environment.ENV_NAME;

          bindToScopeWithWatch(userState.isRiseVisionUser.bind(userState), 'isRiseVisionUser',
            $scope);

          bindToScopeWithWatch(userState.isAssignedPublisher.bind(userState), 'isAssignedPublisher', $scope);
        }
      };
    }
  ]);

